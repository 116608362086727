import gql from 'graphql-tag';

const contratosFormatosTableQuery = gql`
    query contratosFormatos($whereConditions:ContratosFormatosWhereWhereConditions ,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        contratosFormatos(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                cliente_id,
                empresa_id,
                sucursal_id,
                registro_patronal_id,
                sat_tipo_contrato_id,
                referencia_contrato_id,
                nombre,
                contenido,
                variante,
                carpeta,
                consecutivo,
                es_otro_documento,
                url_archivo,
                registroPatronal{
                    id,
                    nombre
                },
                tipoContrato{
                    id,
                    clave
                }
            }  
            paginatorInfo {
                currentPage
                lastItem
                hasMorePages
                lastPage
            }
        }
    }
`;

const contratosVariablesQuery = gql`
    query contratosVariables($whereConditions:ContratosVariablesWhereWhereConditions ,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        contratosVariables(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id
                nombre
                variable
                descripcion
                tipo_variable
                campo_anidado
                campo_referencia_bd
                global
            } 
            paginatorInfo {
                currentPage
                lastItem
                hasMorePages
                lastPage
            }
        }
    }
`;

const contratosImagenesQuery = gql`
    query contratosImagenes($whereConditions:ContratosImagenesWhereWhereConditions ,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        contratosImagenes(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id
                cliente_id
                url
                activo
               }  
            paginatorInfo {
                currentPage
                lastItem
                hasMorePages
                lastPage
            }
        }
    }
`;

const queries = { contratosFormatosTableQuery, contratosVariablesQuery, contratosImagenesQuery};

export default queries;
